import React from 'react'
import {useIntl} from "gatsby-plugin-intl"
import {  graphql } from 'gatsby'
import Layout from '../../components/layout'
import Header from '../../components/header'
import InfoLine from '../../components/infoline'



const ProductPage = (props) => { 
const intl = useIntl()
  return(
  <Layout>
    
    <Header 
      title={intl.formatMessage({ id: "dienstleistungen.Dl-UeberSchrift1" })}
      text={intl.formatMessage({ id: "dienstleistungen.Dl-UeberSchrift2" })}
    />
    
    <div className='row'>
      <div className='col-2'>

      </div>
      <div className='col-8'>

      <p> {intl.formatMessage({ id: "dienstleistungen.Dl-UeberSchrift3" })}
      </p>
      </div>
    </div>

      <InfoLine
      image={props.data.imageOne}
      title={intl.formatMessage({ id: "dienstleistungen.DL-Design" })}
      /* subtitle= 'K 300-2'  */ 
      distanceLeft='col-2'
      text={intl.formatMessage({ id: "dienstleistungen.DL-DesignText" })}
      />

      <InfoLine
      image={props.data.imageTwo}
      distanceLeft='col-2'
      title={intl.formatMessage({ id: "dienstleistungen.Dl-Scannen" })}
      col='col-1'
      /* subtitle= 'K 300-4'  */ 
      text={intl.formatMessage({ id: "dienstleistungen.Dl-ScannenText" })}
      />
      <InfoLine
      image={props.data.imageThree}
      distanceLeft='col-2'
      title={intl.formatMessage({ id: "dienstleistungen.Dl-3dDruck" })}
      col='col-2'
      /*subtitle= 'K 300-4'  */ 
      text={intl.formatMessage({ id: "dienstleistungen.Dl-3dDruckText" })}
      />
      <InfoLine
      image={props.data.imageFour}
      distanceLeft='col-2'
      title={intl.formatMessage({ id: "dienstleistungen.Dl-Nachbearbeitung" })}
      col='col-3'
      /*subtitle= 'K 300-4'  */ 
      text={intl.formatMessage({ id: "dienstleistungen.Dl-NachbearbeitungText" })}
      />
  

    </Layout>
)
}


export default ProductPage;

//fluidImage is defined on imagePage
export const pageQuery = graphql`
  query {

    imageOne: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "foto.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "foto.png" }) {
      ...fluidImage
    }

  }
`